import React, { Component, Fragment } from "react";
import MemeMarketBox from "./MemeMarketBox";
import LoadingBox from "./LoadingBox";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import { ethers } from "../../../buidler/node_modules/ethers";
var _ = require("lodash");

const MemeMarketsObserver = inject("store")(
	observer(
		class MemeMarkets extends Component {
			constructor(props) {
				super(props);
				this.loadMemeMarketBoxes = this.loadMemeMarketBoxes.bind(this);
			}

			loadMemeMarketBoxes() {
				let boxList = [];
				let sortArray = [];
				let sortedArray = [];
				let sortingMemeArray = toJS(this.props.store.memeList);
				_.keys(sortingMemeArray).forEach((key) => {
					sortArray.push({
						name: key,
						supply: sortingMemeArray[key].supply.toString(),
					});
				});

				sortArray = sortArray.sort(function (a, b) {
					return b.supply - a.supply;
				});
				_.keys(sortArray).forEach((key) => {
					sortedArray.push(sortArray[key].name);
				});
				toJS(sortedArray).map((meme) => {
					boxList.push(
						<MemeMarketBox
							key={meme}
							meme={meme}
							price={toJS(this.props.store.memeList)[meme].price.toString()}
							supply={toJS(this.props.store.memeList)[meme].supply.toString()}
						/>
					);
				});

				return boxList;
			}

			render() {
				return (
					<Fragment>
						{this.props.store.loading ? (
							<LoadingBox />
						) : (
							this.loadMemeMarketBoxes()
						)}
					</Fragment>
				);
			}
		}
	)
);

export default MemeMarketsObserver;
