import React, { useContext, useEffect, Component } from "react";
import { ethers } from "../../../buidler/node_modules/ethers";
import { observer, inject } from "mobx-react";

const FillDataObserver = inject("store")(
	observer(
		class FillData extends Component {
			componentDidMount() {
				this.poll();
			}

			poll = async () => {
				this.props.store.provider.on("newmeme", (x) => {
					console.log("memefire", x);
				});
				this.props.store.provider.on("block", (x) => {
					console.log("block", x);
				});
				let tx = await this.props.store.provider.listAccounts();
				console.log(tx);
			};

			call2 = async () => {
				let vtz = await this.props.store.viewer.viewUserData(
					"0xcaa6b1207bbe1393e6f039c0f91018bf860c885e",
					"Pepe"
				);
				console.log(vtz);
			};

			createMeme = async () => {
				let ctm = await this.props.store.thinkpol.addMarket("Ethereum");
				ctm = await ctm.wait();
				let ctr = await this.props.store.thinkpol.addMarket("Yande");
				ctr = await ctr.wait();
				let l = await this.props.store.thinkpol.addMarket("monke");
				l = await l.wait();
				let p = await this.props.store.thinkpol.addMarket("Max Keiser");
				p = await p.wait();
				let i = await this.props.store.thinkpol.addMarket("Pineapple");
				i = await i.wait();
				let h = await this.props.store.thinkpol.addMarket("MetaIrony");
				h = await h.wait();
				let v = await this.props.store.thinkpol.addMarket("Meme Wizards");
				v = await v.wait();
				let m = await this.props.store.thinkpol.addMarket("ThinkPol");
				m = await m.wait();
			};

			loaded = async () => {
				this.props.store.switchLoading();
			};

			sendEth = async () => {
				let tx = await this.props.store.signer.sendTransaction({
					to: "0xc783df8a850f42e7f7e57013759c285caa701eb6",
					value: ethers.BigNumber.from("1000000000000000000"),
				});
				let txt = await tx.wait();
			};

			getData = async () => {
				// let tx = await state.thinkpol.addMarket("testMarket");
				// let rept = await tx.wait();
				// console.log("addmarket", rept);

				// console.log("first blockchain call!", tx);
				// const receipt = await tx.wait();
				// console.log(receipt);
				// let rv = await state.thinkpol.buyPriceInWei("Pepe", 100);
				// const rec = await rv.wait();
				// console.log("check for wei value", rec);

				// console.log("view ", vtx);
				// let vta = await state.thinkpol.viewTest();
				// console.log("viewtest", vta);
				// let sell = await state.thinkpol.sell("Pepe", 100);
				console.log("fill fires");
			};

			render() {
				return (
					<>
						{/* <button onClick={this.createMeme}>Send&Emit</button>
						<br />
						<button onClick={this.loaded}>Finish Loading</button>
						<br />
						<button onClick={this.sendEth}>Send Eth</button> */}
					</>
				);
			}
		}
	)
);
export default FillDataObserver;
