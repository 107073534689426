import React from "react";
import { Box, Image } from "@chakra-ui/core";
import pattern from "../img/pattern.png";
import stonks from "../img/stonks.png";
import triangles from "../img/triangles.png";

function InfoCard() {
	return (
		<Box position="relative" h="45vh">
			<Box
				id="InfoCard"
				className="draw meet noselect"
				h="45vh"
				mx="10%"
				my="10px"
				bg="purple.700"
				rounded="md"
				display="flex"
				flex-flexDirection="row"
				justifyContent="center"
				alignItems="center"
				color="white"
				zIndex="-1"
				// opacity="1"
			>
				<Image
					src={stonks}
					alt=""
					opacity="0.3"
					z-index="-1"
					position="absolute"
					pointerEvents="none"
					right="0"
				/>
				<Image
					src={triangles}
					alt=""
					opacity="0.02"
					z-index="-1"
					position="absolute"
					pointerEvents="none"
					width="100%"
					height="100%"
					object-fit="cover"
				/>
				<Box
					position="absolute"
					left="20%"
					top="0"
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					alignItems="center"
					color="white"
					just
				>
					<Box alignItems="center" position="absolute">
						<Box fontSize="3rem" color="#F6AD55">
							Meme Markets Are Here
						</Box>
					</Box>
				</Box>
				<Box position="absolute" bottom="0">
					<p>Put your memes to work.</p>
					<p>Live on Rinkeby Testnet.</p>
				</Box>
			</Box>

			<Box></Box>
		</Box>
	);
}

export default InfoCard;
