import React from "react";
import { Box } from "@chakra-ui/core";
import { GiBrainstorm } from "react-icons/gi";

function Navbar() {
	return (
		<Box
			display="flex"
			flexDirection="row"
			justifyContent="space-around"
			top="0"
			w="100vw"
			h="20px"
			bg="cream.100"
		>
			<Box
				pt="5px"
				// top="50px"
				fontWeight="bold"
				overflow="none"
				position="absolute"
				color="purple.700"
				display="flex"
				flexDirection="row"
			>
				ThinkPol <GiBrainstorm size="1.5rem" color="#F6AD55" />
			</Box>
			<Box>{""}</Box>
		</Box>
	);
}

export default Navbar;
