import React from "react";
import { Box, Image } from "@chakra-ui/core";
import { AiOutlineLineChart, AiOutlineSearch } from "react-icons/ai";
import { GiMeshNetwork } from "react-icons/gi";

function InfoText() {
	return (
		<Box display="flex" flexDirection="column" justifyContent="center">
			<Box mb="25px" mt="25px">
				<Box mb="10px" textAlign="center" fontWeight="bold">
					MemeLords Rejoice
				</Box>
				<Box textAlign="center" mx="20%">
					ThinkPol provides price discovery for memes by associating them with
					augmented bonding curves on the Ethereum blockchain. Users signal
					their thoughts by buying shares in memes which they value.
				</Box>
			</Box>
			<Box
				margin-top="80px"
				display="flex"
				flexDirection="row"
				justifyContent="space-around"
			>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Box>
						<AiOutlineLineChart size="3rem" color="#553C9A" />
					</Box>
					<Box mt="5px" m="5px" fontWeight="bold">
						Zero Liquidity
					</Box>
					<Box w="250px" textAlign="center">
						Bonding curves allow for the instantiation of topical markets with
						no or low initial liquidity. Liquidity is provided by a smart
						contract controlled automated market making mechanism. All users
						know ahead of time what the supply and price schedules of the market
						are.
					</Box>
				</Box>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Box>
						<AiOutlineSearch size="3rem" color="#553C9A" />
					</Box>
					<Box mt="5px" m="5px" fontWeight="bold">
						Keyword Markets
					</Box>
					<Box w="250px" textAlign="center">
						ThinkPol provides a central register for monetized keywords. This
						allows for discrete price signalling not only on events, but upon
						the abstractions which form the ground of our culture and
						understanding.
					</Box>
				</Box>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Box>
						<GiMeshNetwork size="3rem" color="#553C9A" />
					</Box>
					<Box mt="5px" m="5px" fontWeight="bold">
						MemeDAOs
					</Box>
					<Box w="250px" textAlign="center">
						Shares in Meme Markets can be used in many ways besides pure
						speculation. A simple MemeDAO might use the shares of a Meme Market
						to form a collective in service of the gestalt intelligence of that
						meme. Meme Markets are a powerful tool for coordinating the behavior
						of groups who have the same memes in mind.
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default InfoText;
