import React from "react";
import {
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Box,
	ThemeProvider,
} from "@chakra-ui/core";
import MemeMarkets from "./MemeMarkets";
import CreateMemeBox from "./CreateMemeBox";
import InfoCard from "./InfoCard";
import InfoText from "./InfoText";
import UserPortfolio from "./UserPortfolio";

function MainTabs() {
	return (
		<ThemeProvider>
			<Tabs variant="line" defaultIndex={0}>
				<TabList
					display="flex"
					justifyContent="center"
					bg="white"
					color="gray.400"
				>
					<Tab
						fontSize="lg"
						_selected={{
							color: "purple.700",
							borderBottom: "1px black solid",
							borderColor: "purple.700",
						}}
						_focus={{
							boxShadow: "none",
						}}
					>
						Meme Markets
					</Tab>
					<Tab
						fontSize="lg"
						_selected={{
							color: "purple.700",
							borderBottom: "1px black solid",
							borderColor: "purple.700",
						}}
						_focus={{ boxShadow: "none" }}
					>
						Portfolio
					</Tab>
				</TabList>

				<TabPanels>
					<TabPanel>
						<Box className="Content-Wrapper" w="70%" mx="auto" mt="20px">
							<InfoCard />
							<InfoText />
							<CreateMemeBox />
							<MemeMarkets />
						</Box>
					</TabPanel>
					<TabPanel>
						<Box className="Content-Wrapper" w="70%" mx="auto" mt="20px">
							<Box fontWeight="bold" mx="auto">
								Your Meme Market Balances
							</Box>
							<UserPortfolio />
						</Box>
					</TabPanel>
					<TabPanel>
						<Box className="Content-Wrapper" w="70%" mx="auto" mt="20px">
							<InfoCard />
						</Box>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</ThemeProvider>
	);
}

export default MainTabs;
