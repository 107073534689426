import React, { Component, useState } from "react";
import {
	Box,
	Input,
	Button,
	ThemeProvider,
	InputGroup,
	InputLeftAddon,
} from "@chakra-ui/core";
// import Store, { MemeContext } from "../Store";
import { ethers } from "../../../buidler/node_modules/ethers";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

const MemeMarketBoxObserver = inject("store")(
	observer(
		class MemeMarketBox extends Component {
			constructor() {
				super();
				this.handleBuy = this.handleBuy.bind(this);
				this.handleSell = this.handleSell.bind(this);
				// this.handleEth = this.handleEth.bind(this);
				this.handleShares = this.handleShares.bind(this);
				this.calcWei = this.calcWei.bind(this);
				// this.calcShares = this.calcShares.bind(this);
				this.convertToBigNum = this.convertToBigNum.bind(this);
				this.decimalize = this.decimalize.bind(this);
				this.state = {
					wei: {},
					shares: {},
				};
				this.multiplier = ethers.BigNumber.from("100000000");
				this.precision = ethers.BigNumber.from("1000000000000000000");
			}

			// handleEth = (event) => {
			// 	console.log(event.target.value);
			// 	if (
			// 		event.target.value != "-" &&
			// 		event.target.value != "e" &&
			// 		event.target.value != "E" &&
			// 		event.target.value != ""
			// 	) {
			// 		console.log("multi", this.multiplier);
			// 		this.state.wei = this.convertToBigNum(event.target.value);
			// 		// .mul(this.multiplier);
			// 		console.log("wei", this.state.wei);
			// 		document.getElementById(
			// 			`SharesInput-${this.props.meme}`
			// 		).value = this.calcShares();
			// 	} else {
			// 		event.preventDefault();
			// 		if (event.target.value == "") {
			// 			document.getElementById(
			// 				`SharesInput-${this.props.meme}`
			// 			).value = null;
			// 			this.state.wei = {};
			// 		}
			// 	}
			// };

			handleShares = (event) => {
				console.log("event", event.target.value);
				console.log(event.target.value.slice(-1));
				console.log(event.target.value.slice(-2));

				if (
					event.target.value.slice(-1) != "-" &&
					event.target.value.slice(-2, -1) != "-" &&
					event.target.value.slice(0, 1) != "-" &&
					event.target.value.slice(-1) != "e" &&
					event.target.value.slice(-1) != "E" &&
					event.target.value.slice(-1) != "" &&
					event.target.value.slice(-1) != "." &&
					event.target.value.slice(-2, -1) != "." &&
					event.target.value.slice(0, 1) != "."
				) {
					this.state.shares = this.convertToBigNum(event.target.value);
					document.getElementById(
						this.props.balance
							? `BalanceEthInput-${this.props.meme}`
							: `EthInput-${this.props.meme}`
					).value = this.calcWei();
				} else {
					event.preventDefault();
					document.getElementById(
						this.props.balance
							? `BalanceSharesInput-${this.props.meme}`
							: `SharesInput-${this.props.meme}`
					).value = "";
					document.getElementById(
						this.props.balance
							? `BalanceEthInput-${this.props.meme}`
							: `EthInput-${this.props.meme}`
					).value = "";
					this.state.wei = {};
					console.log("Only Enter Integers, plz");
				}
			};

			convertToBigNum = (input) => {
				return ethers.BigNumber.from(input);
			};

			// calcShares = () => {
			// 	console.log("wei", this.state.wei);
			// 	console.log(this.props.supply);
			// 	let x = this.state.wei
			// 		.mul(3)
			// 		.add(this.convertToBigNum(this.props.supply).pow(3));
			// 	console.log(x.toNumber());
			// 	x = x.sub(this.props.supply);
			// 	this.state.shares = x;
			// 	x = x.toString();
			// 	// return x == 1 ? null : x;
			// 	return x;
			// };

			calcWei = () => {
				let x = this.state.shares.add(this.props.supply);
				x = x.mul(this.precision).mul(this.multiplier);
				x = x.pow(3);
				x = x.div(this.precision);
				console.log("b^3 ", x.toString());
				let y = this.convertToBigNum(this.props.supply)
					.mul(this.precision)
					.mul(this.multiplier);
				y = y.pow(3);
				y = y.div(this.precision);
				x = x.sub(y);
				x = x.mul(this.multiplier);
				x = x.div(3);
				x = x.div(this.multiplier);
				x = x.div(this.multiplier);
				x = x.div(this.multiplier);
				x = x.div(this.precision);
				x = x.div(this.precision);
				this.state.wei = x;
				x = x.toString();
				return this.decimalize(x);
			};

			decimalize(num) {
				if (num.length == 18) {
					num = num.padStart(20, "0.");
				} else if (num.length < 18) {
					num = num.padStart(18, "0");
					num = num.padStart(20, "0.");
				} else if (num.length > 18) {
					let decimal = num.slice(-18);
					let integer = num.slice(0, num.length - 18);
					num = integer.concat(".");
					num = num.concat(decimal);
				}
				return num;
			}

			handleBuy = async () => {
				if (JSON.stringify(this.state.shares) != "{}") {
					let shareStore = this.state.shares.add(this.props.supply);
					let buy = await this.props.store.thinkpol.buy(
						this.props.meme,
						this.state.shares,
						{ value: this.state.wei.add(this.convertToBigNum(100)) }
					);
					console.log("buy", buy);
					let receipt = await buy.wait();
					console.log("receipt", receipt);
					if (receipt.status == 1) {
						this.props.store.updateMemeListSupply(this.props.meme, shareStore);
						document.getElementById(
							this.props.balance
								? `BalanceEthInput-${this.props.meme}`
								: `EthInput-${this.props.meme}`
						).value = null;
						document.getElementById(
							this.props.balance
								? `BalanceSharesInput-${this.props.meme}`
								: `SharesInput-${this.props.meme}`
						).value = null;
						console.log("check this", toJS(this.props.store.userBalance));
						toJS(this.props.store.userBalance)[this.props.meme]
							? this.props.store.addUserBalance(
									this.props.meme,
									shareStore.toNumber()
							  )
							: this.props.store.addUserMeme(
									this.props.meme,
									shareStore.toNumber()
							  );
						this.state.wei = {};
						this.state.shares = {};
					}
				}
			};

			handleSell = async () => {
				if (JSON.stringify(this.state.shares) != "{}") {
					let subStore = this.state.shares.toNumber();
					let shareStore = this.convertToBigNum(this.props.supply).sub(
						this.state.shares
					);
					let sell = await this.props.store.thinkpol.sell(
						this.props.meme,
						this.state.shares
					);
					let receipt = await sell.wait();
					console.log(receipt);
					if (receipt.status == 1) {
						this.props.store.updateMemeListSupply(this.props.meme, shareStore);
						document.getElementById(
							this.props.balance
								? `BalanceEthInput-${this.props.meme}`
								: `EthInput-${this.props.meme}`
						).value = null;
						document.getElementById(
							this.props.balance
								? `BalanceSharesInput-${this.props.meme}`
								: `SharesInput-${this.props.meme}`
						).value = null;
						console.log("subStore", subStore);
						this.props.store.sellUserBalance(this.props.meme, subStore);
						this.state.wei = {};
						this.state.shares = {};
					}
				}
			};

			render() {
				return (
					<ThemeProvider>
						<Box
							className="MemeMarketBox"
							bg="gray.100"
							w="90%"
							h="120px"
							p={10}
							color="black"
							mx="auto"
							my="15px"
							borderLeft="3px solid black"
							borderColor="purple.700"
							display="flex"
							flexDirection="row"
							alignItems="center"
							position="realtive"
							justifyContent="space-around"
						>
							<Box position="absolute" left="20">
								{this.props.meme}
							</Box>

							{this.props.balance ? (
								<Box
									display="flex"
									flexDirection="column"
									alignItems="center"
									position="absolute"
									left="250px"
								>
									<Box ml="10%">Balance</Box>
									<Box>{this.props.balance}</Box>
								</Box>
							) : null}

							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								position="absolute"
								mr={this.props.balance ? "0px" : "0px"}
							>
								<Box>Supply</Box>
								<Box>{this.props.supply}</Box>
							</Box>

							<Box
								display="flex"
								flexDirection="row"
								position="absolute"
								right="7px"
							>
								<Box>
									<InputGroup mb="3px">
										<InputLeftAddon children="  Qty" bg="gray.200" />
										<Input
											id={
												this.props.balance
													? `BalanceSharesInput-${this.props.meme}`
													: `SharesInput-${this.props.meme}`
											}
											onChange={this.handleShares}
											placeholder="Shares"
											size="sm"
											type="number"
											pattern="[0-9]"
											title="Integers Only"
										/>
									</InputGroup>

									<InputGroup>
										<InputLeftAddon children="Price" bg="gray.200" />
										<Input
											id={
												this.props.balance
													? `BalanceEthInput-${this.props.meme}`
													: `EthInput-${this.props.meme}`
											}
											placeholder="Ether"
											size="sm"
											type="number"
											isReadOnly="true"
										/>
									</InputGroup>
								</Box>
								<Box display="flex" flexDirection="column">
									<Button
										opacity="0.85"
										variantColor="green"
										onClick={this.handleBuy}
										mb="2px"
									>
										Buy
									</Button>
									<div height="3px"></div>

									<Button
										opacity="0.85"
										variantColor="red"
										onClick={this.handleSell}
									>
										Sell
									</Button>
								</Box>
							</Box>
						</Box>
					</ThemeProvider>
				);
			}
		}
	)
);
export default MemeMarketBoxObserver;
