import React, { Fragment, useState } from "react";
import { ThemeProvider, CSSReset, Box } from "@chakra-ui/core";
import ConnectEthers from "./components/ConnectEthers";
import Navbar from "./components/Navbar";
import MainTabs from "./components/MainTabs";
import "./style-overrides/override.scss";

const App = () => {
	return (
		<Fragment>
			<ThemeProvider>
				<CSSReset />
				<ConnectEthers />
				<Navbar />
				<MainTabs />
			</ThemeProvider>
		</Fragment>
	);
};

export default App;
