import { observable, computed, action, decorate, autorun, toJS } from "mobx";
import { observer } from "mobx-react";

// const initialState = {
// 	loading: true,
// 	userAddress: null,
// 	thinkpol: null,
// 	viewer: null,
// };

class Store {
	loading = true;
	portfolioLoading = true;
	address = null;
	thinkpol = null;
	viewer = null;
	provider = null;
	signer = null;
	memeList = {};
	memeArray = [];
	userBalance = [];

	setAddress(input) {
		this.address = input;
	}
	setThinkpol(input) {
		this.thinkpol = input;
	}
	setViewer(input) {
		this.viewer = input;
	}
	setProvider(input) {
		this.provider = input;
	}
	setSigner(input) {
		this.signer = input;
	}
	switchLoading() {
		this.loading = !this.loading;
	}
	switchPortfolioLoading() {
		this.portfolioLoading = !this.portfolioLoading;
	}
	initializeMemeList(input) {
		this.memeList = input;
		console.log("memeList fires");
		console.log(toJS(this.memeList));
		this.switchPortfolioLoading();
	}
	initializeMemeArray(input) {
		this.memeArray = input;
	}
	updateMemeListSupply(meme, amount) {
		this.memeList[meme].supply = amount;
	}
	createNewMeme(meme) {
		meme = meme.toString();
		this.memeArray.push(meme);
		this.memeList[meme] = { price: 0, supply: 1 };
	}
	initializeUserBalance(memeList) {
		this.userBalance = memeList;
		console.log("initialize userbalance ", this.userBalance);
	}
	addUserMeme(meme, amount) {
		meme = meme.toString();
		console.log("addUserMeme fire");
		console.log(toJS(this.userBalance)[meme]);
		this.userBalance[meme] = { item: { name: meme, balance: amount - 1 } };
		console.log(meme);
		console.log(toJS(this.userBalance)[meme]);
		console.log(toJS(this.userBalance));
		console.log("add user meme and balance");
	}
	addUserBalance(meme, amount) {
		meme = meme.toString();
		console.log(
			"addUserBalance fire",
			Number(toJS(this.userBalance)[meme].item.balance) + amount
		);
		this.userBalance[meme].item.balance = amount - 1;
		console.log("add user balance", this.userBalance[meme].balance);
	}
	sellUserBalance(meme, amount) {
		meme = meme.toString();
		console.log("sell fires!");
		console.log(toJS(this.userBalance)[meme], amount);
		this.userBalance[meme].item.balance =
			Number(toJS(this.userBalance)[meme].item.balance) - amount;
		console.log("sell ends!");
	}
}

decorate(Store, {
	loading: observable,
	portfolioLoading: observable,
	address: observable,
	thinkpol: observable,
	viewer: observable,
	provider: observable,
	signer: observable,
	memeList: observable,
	memeArray: observable,
	userBalance: observable,
	switchLoading: action,
	switchPortfolioLoading: action,
	setAddress: action,
	setThinkpol: action,
	setViewer: action,
	setProvider: action,
	setSigner: action,
	initializeMemeList: action,
	initializeMemeArray: action,
	updateMemeListSupply: action,
	createNewMeme: action,
	initializeUserBalance: action,
	addUserMeme: action,
	addUserBalance: action,
	sellUserBalance: action,
});

var store = (window.store = new Store());
console.log("store", store.loading);

export default Store;
