import React, { Component, useState } from "react";
import {
	Box,
	Input,
	Button,
	ThemeProvider,
	InputGroup,
	InputLeftAddon,
	Icon,
} from "@chakra-ui/core";
// import Store, { MemeContext } from "../Store";
import { ethers } from "../../../buidler/node_modules/ethers";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

export const CreateMemeBoxObserver = inject("store")(
	observer(
		class CreateMemeBox extends Component {
			constructor() {
				super();
				this.handleInput = this.handleInput.bind(this);
				this.handleCreate = this.handleCreate.bind(this);
				this.state = {
					input: null,
				};
			}

			handleInput = (e) => {
				this.state.input = e.target.value;
			};

			handleCreate = async () => {
				let name = this.state.input;
				if (this.state.input != null) {
					let create = await this.props.store.thinkpol.addMarket(
						this.state.input
					);
					console.log("create", create);
					let receipt = await create.wait();
					console.log("receipt", receipt);
					if (receipt.status == 1) {
						this.props.store.createNewMeme(name);
						console.log(
							"input value",
							document.getElementById(`CreateMarketInput`).value
						);
						this.state.input = null;
					}
				}
			};

			render() {
				return this.props.store.loading ? null : (
					<ThemeProvider>
						<Box
							className="MemeMarketBox"
							bg="purple.700"
							w="90%"
							h="120px"
							p={10}
							color="black"
							mx="auto"
							my="15px"
							borderLeft="3px solid black"
							borderColor="orange.300"
							display="flex"
							flexDirection="row"
							alignItems="center"
							position="realtive"
							justifyContent="space-around"
						>
							<Box color="white" fontSize="2rem">
								Marketize Your Meme
							</Box>
							<Box display="flex" flex-flexDirection="row">
								<InputGroup>
									<InputLeftAddon children={"Meme"} bg="gray.300" />
									<Input
										onChange={this.handleInput}
										id="CreateMarketInput"
										color="purple.700"
										placeholder="Feed me dank memes"
										opacity="0.85"
										variant="outline"
										bg="none"
									></Input>
								</InputGroup>
								<Button onClick={this.handleCreate} bg="orange.300" ml="20px">
									Create
								</Button>
							</Box>
						</Box>
					</ThemeProvider>
				);
			}
		}
	)
);

export default CreateMemeBoxObserver;
