import React from "react";
import { Box, Skeleton, ThemeProvider } from "@chakra-ui/core";

function LoadingBox() {
	return (
		<ThemeProvider>
			<Skeleton>
				<Box
					className="MemeMarketBox"
					bg="gray.100"
					w="90%"
					h="120px"
					p={10}
					color="black"
					mx="auto"
					my="15px"
					borderLeft="3px solid black"
					borderColor="purple.700"
					display="flex"
					flexDirection="row"
					position="realtive"
				></Box>
			</Skeleton>
			<Skeleton>
				<Box
					className="MemeMarketBox"
					bg="gray.100"
					w="90%"
					h="120px"
					p={10}
					color="black"
					mx="auto"
					my="15px"
					borderLeft="3px solid black"
					borderColor="purple.700"
					display="flex"
					flexDirection="row"
					position="realtive"
				></Box>
			</Skeleton>
			<Skeleton>
				<Box
					className="MemeMarketBox"
					bg="gray.100"
					w="90%"
					h="120px"
					p={10}
					color="black"
					mx="auto"
					my="15px"
					borderLeft="3px solid black"
					borderColor="purple.700"
					display="flex"
					flexDirection="row"
					position="realtive"
				></Box>
			</Skeleton>
		</ThemeProvider>
	);
}

export default LoadingBox;
