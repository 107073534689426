import React, { Component, useState, Fragment } from "react";
import {
	Box,
	Input,
	Button,
	ThemeProvider,
	InputGroup,
	InputLeftAddon,
	Icon,
} from "@chakra-ui/core";
// import Store, { MemeContext } from "../Store";
import { ethers } from "../../../buidler/node_modules/ethers";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import { timingSafeEqual } from "crypto";
import MemeMarketBox from "./MemeMarketBox";
import LoadingBox from "./LoadingBox";
var _ = require("lodash");

const UserPortfolioObserver = inject("store")(
	observer(
		class UserPortfolio extends Component {
			constructor(props) {
				super(props);
				this.loadUserBalanceBoxes = this.loadUserBalanceBoxes.bind(this);
			}

			loadUserBalanceBoxes = () => {
				console.log("loading", this.props.store.portfolioLoading);
				let boxList = [];
				let servePortfolio = [];
				let sortArray = [];
				console.log("userBalance", toJS(this.props.store.userBalance));
				_.keys(toJS(this.props.store.userBalance)).forEach((key) => {
					let bal = toJS(this.props.store.userBalance[key].item.balance);
					if (bal != 0) {
						sortArray.push({ name: key, supply: bal });
					}
				});
				sortArray = sortArray.sort(function (a, b) {
					return b.supply - a.supply;
				});
				_.keys(sortArray).forEach((key) => {
					boxList.push(sortArray[key].name);
				});
				console.log("boxList", boxList);
				boxList.map(async (meme) => {
					let x = 0;
					console.log(x, meme);
					{
						console.log(x, "price object", toJS(this.props.store.memeList));
					}
					servePortfolio.push(
						<MemeMarketBox
							key={meme}
							meme={meme}
							price={toJS(this.props.store.memeList)[meme].price.toString()}
							supply={toJS(this.props.store.memeList)[meme].supply.toString()}
							balance={toJS(this.props.store.userBalance)[
								meme
							].item.balance.toString()}
						/>
					);
					x++;
				});

				return servePortfolio;
			};

			render() {
				return (
					<Fragment>
						{this.props.store.portfolioLoading ? (
							<LoadingBox />
						) : (
							this.loadUserBalanceBoxes()
						)}
					</Fragment>
				);
			}
		}
	)
);

export default UserPortfolioObserver;
