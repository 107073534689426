import React, { useContext, useEffect, Component } from "react";
import { ethers } from "../../../buidler/node_modules/ethers";
import { observer, inject } from "mobx-react";
import { observable, decorate } from "mobx";
import FillData from "./FillData";
const abi = require("../Contract/abi");
const bytecode = require("../Contract/bytecode");

const ConnectEthersObserver = inject("store")(
	observer(
		class ConnectEthers extends Component {
			constructor(props) {
				super(props);
				this.checkThinkPolStatus = this.checkThinkPolStatus.bind(this);
				this.setSigners = this.setSigners.bind(this);
				this.setSigner = this.setSigner.bind(this);
				this.checkMetaMask = this.checkMetaMask.bind(this);
				this.initializeStoreMemes = this.initializeStoreMemes.bind(this);
				this.setUserBalance = this.setUserBalance.bind(this);
			}

			componentDidMount = async () => {
				await this.checkMetaMask();
			};

			setUserAddress = (input) => {
				this.props.store.setAddress(input);
			};

			initializeStoreMemes = async () => {
				let a = await this.props.store.viewer.viewMemeList();
				this.props.store.initializeMemeArray(a);
				let b = await this.processArray(a);
				this.props.store.initializeMemeList(b);
				this.props.store.switchLoading();
			};

			setUserBalance = async () => {
				let arr = {};
				let address = this.props.store.address;
				let userList = await this.props.store.viewer.viewUserList(address);
				console.log("userList", userList);
				for (const item of userList) {
					let x = await this.props.store.viewer.viewUserBalance(address, item);
					arr[item] = { item: { name: item, balance: x.toString() } };
				}
				this.props.store.initializeUserBalance(arr);
				console.log("user balance", arr);
				// this.props.store.switchPortfolioLoading();
			};

			processArray = async (array) => {
				let arr = {};
				for (const item of array) {
					let x = await this.props.store.viewer.viewMemeData(item);
					arr[item] = { price: x[1], supply: x[2] };
				}
				return arr;
			};

			checkThinkPolStatus = () => {
				return this.props.store.thinkpol;
			};

			setProvider = (x) => {
				this.props.store.setProvider(x);
			};

			setSigners = (x, y) => {
				this.props.store.setThinkpol(x);
				this.props.store.setViewer(y);
			};

			setSigner = (x) => {
				this.props.store.setSigner(x);
			};

			checkMetaMask = async () => {
				window.addEventListener("load", async () => {
					if (typeof web3 !== "undefined") {
						console.log("Web3 injected");
						await window.ethereum.enable();
						let provider = new ethers.providers.Web3Provider(window.ethereum);
						this.setProvider(provider);
						let signer = provider.getSigner(0);
						this.setSigner(signer);
						let address = await signer.getAddress();
						this.setUserAddress(address);
						if (address == undefined) {
							console.log("Metamask found, but not logged in");
							// Toaster with: Please log in to MetaMask uwu
						} else {
							console.log("Metamask found and logged in");
							let signInstance = new ethers.Contract(
								"0x3CeB4c2851c1DDBE5577FD0e8bd089de2746eDDA",
								abi,
								signer
							);
							let viewInstance = new ethers.Contract(
								"0x3CeB4c2851c1DDBE5577FD0e8bd089de2746eDDA",
								abi,
								provider
							);
							if (this.props.store.loading) {
								this.setSigners(signInstance, viewInstance);
								this.initializeStoreMemes();
								this.setUserBalance();
							}
						}
					} else {
						console.log("Web3 not injected");
						// Toaster with: MetaMask not found, please install or log in to MetaMask
					}
				});
			};

			render() {
				return !this.props.store.thinkpol ? null : <FillData />;
			}
		}
	)
);

// decorate(ConnectEthersObserver, {
// 	thinkpol: observable,
// });

export default ConnectEthersObserver;
